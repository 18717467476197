<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Reclamaciones')+': '+$t('Vehículo')}} </h3>
		</div>
		<div class="p-col-12" v-if="!mostrar">
			<div class="card">
                <DataTable :value="claims" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
                    <template #header>
                        <div class="table-header">
                            <Dropdown v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Filtrar()"></Dropdown>
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
                            </span>
                        </div>
                    </template>
                    <Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 100px">
                        <template #body="slotProps">
							<strong :style="'color:'+slotProps.data.color" v-if="slotProps.data.estado!=2 && i18n.locale() == 'es'">
								{{slotProps.data.estado_nombre}}
							</strong>
							<strong :style="'color:'+slotProps.data.color" v-if="slotProps.data.estado!=2 && i18n.locale() == 'en'">
								{{slotProps.data.estado_name}}
							</strong>
							<strong :style="'color:'+slotProps.data.color" v-if="slotProps.data.estado==2 && i18n.locale() == 'es'">
								{{slotProps.data.estado2_nombre}}
							</strong>
							<strong :style="'color:'+slotProps.data.color" v-if="slotProps.data.estado==2 && i18n.locale() == 'en'">
								{{slotProps.data.estado2_name}}
							</strong>
                        </template>
                    </Column>   
                    <Column field="codigo" :header="$t('Claim')+' #'" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.codigo}}                              
                        </template>
                    </Column>
                    <Column field="poliz" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.poliz}}                              
                        </template>
                    </Column>
                    <Column field="cliente" :header="$t('Cliente')+' #'" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.cliente}}                              
                        </template>
                    </Column>
                    <Column field="fecha" :header="$t('Fecha del incidente')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{convfech(slotProps.data.fecha)}}
                        </template>
                    </Column>
					<Column :header="$t('Acciones')" headerStyle="width: 80px">
						<template #body="slotProps">
							<Button :disabled ="$store.state.loading" icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="Editar(slotProps.data)" v-if="edit && slotProps.data.forence"/> 
							<Button :disabled ="$store.state.loading" icon="pi pi-eye" class="p-button-rounded p-button-info p-mr-2" @click="Editar(slotProps.data)" v-if="edit && !slotProps.data.forence"/> 
							<Tag class="p-mr-2" icon="pi pi-exclamation-triangle" severity="warning" :value="$t('Pendiente')" v-if="slotProps.data.forence"></Tag>
						</template>
					</Column>

                </DataTable>
			</div>
        </div>
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<Policy :datos="seleccion.poliz" :detall="seleccion.vehiculo"/>
		</div>
		<div class="p-col-12 p-md-6" v-if="mostrar">
			<Basic :datos="seleccion.poliz" :detall="seleccion.vehiculo"/>
		</div>
		<div class="p-col-12 p-md-6" v-if="mostrar">
			<Information :datos="seleccion.poliz" :detall="seleccion.vehiculo"/>
		</div>
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<Accordion>
				<AccordionTab :header="$t('Beneficiario')">
					<Beneficiary :edit="false" :tipo_veh="tipos" :datos="seleccion.beneficiario"/>
				</AccordionTab>
			</Accordion>
		</div>	
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<Accordion>
				<AccordionTab :header="$t('Forence')">
					<Forence :datos="seleccion" v-if="seleccion.forence"/>
					<Detalle :datos="seleccion" :estados="seleccion.estado" v-else/>
				</AccordionTab>
			</Accordion>
		</div>
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h4>{{$t('Estatus')}}:
							<strong :style="'color:'+seleccion.color" v-if="seleccion.estado!=2 && i18n.locale() == 'es'">
								{{seleccion.estado_nombre}}
							</strong>
							<strong :style="'color:'+seleccion.color" v-if="seleccion.estado!=2 && i18n.locale() == 'en'">
								{{seleccion.estado_name}}
							</strong>
							<strong :style="'color:'+seleccion.color" v-if="seleccion.estado==2 && i18n.locale() == 'es'">
								{{estado2.nombre}}
							</strong>
							<strong :style="'color:'+seleccion.color" v-if="seleccion.estado==2 && i18n.locale() == 'en'">
								{{estado2.name}}
							</strong>
						</h4>
					</template>
					<template #content>					
						<div class="p-fluid p-formgrid p-grid">						
							<div class="p-field p-col-2">
								{{$t('Observaciones')}}: 
							</div>
							<div class="p-field p-col-10">
								{{seleccion.observacion}}
							</div>
						</div>
					</template>
					<template #footer>
						<Button :disabled ="$store.state.loading" :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary p-mr-2 p-mb-2" @click="Guardar(true)"  v-if="seleccion.forence==true"/>
						<Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger p-mr-2 p-mb-2" @click="mostrar=false"/>
						<Button :disabled ="$store.state.loading" :label="$t('Procesar')" icon="pi pi-check" class="p-button-infor p-mr-2 p-mb-2" @click="Guardar(false)"  v-if="seleccion.forence==true"/>
					</template>
				</Card>
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Reclamos','Claim2');
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import { defineAsyncComponent } from "@vue/runtime-core";

	export default {
		components: {
			Policy: defineAsyncComponent(() => import('../comp_claim/Policy.vue')),
			Basic: defineAsyncComponent(() => import('../comp_claim/Basic.vue')),
			Information: defineAsyncComponent(() => import('../comp_claim/Information.vue')),
			Beneficiary: defineAsyncComponent(() => import('../comp_claim/Beneficiary.vue')),
			Forence: defineAsyncComponent(() => import('../comp_claim/Forence.vue')),
			Detalle: defineAsyncComponent(() => import('../comp_claim/Detalle.vue')),
		},
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: {nombre: 'Todos', name: 'All', code: 0, color: ''},
				opciones: [{nombre: 'Todos', name: 'All', code: 0, color: ''}],
				resultado: [],
				insert: false,
				edit: false,
				del: false,
				poliza: null,
				filters: {},
				selectedCustomers: null,
				submitted: false,
				size: '60vw',
				seleccion: {},
				mostrar: false,
				tipos: [],
				estados: [],
				validationErrors: {},
				estado: null,
				claims: [],
				anexos: [],
				foren: [],
				imegan: null,
				subtotal: 0,
				total: 0,
				impuesto: 0,
				timeloss: 0,
				risk: 0
			}
		},
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.i18n = useI18nPlugin();
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.anexos = [];
				this.mostrar = false;
				Consulta.Procesar('Cons_For',{
					id: 0,
				}).then(response => {
					//this.$store.state.error = response;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.claims = response.result.claims;
					this.resultado = response.result.claims;
					if (response.result.opciones){
						response.result.opciones.forEach(val => {
							this.opciones.push(val);
						});
					}
					this.estados = response.result.estados;
					this.tipos = response.result.tipos;
					this.impuesto = response.result.impuesto;
					this.risk = response.result.risk;
				});
			},
			Filtrar() {		
				if (this.opcion.code==0){
					this.claims = this.resultado;
				} else {
					this.claims = this.resultado.filter(val => val.estado == this.opcion.code);			
				}
			},
			Editar(data) {
				this.seleccion = {};
				Consulta.Procesar('Detalle',{
					claim: data,
				}).then(response => {
					//this.$store.state.error = response;
					var selec = {...data};
					selec.poliz = response.result.poliz;
					selec.vehiculo = response.result.vehiculo;
					selec.anexos = response.result.anexos;
					selec.foren = response.result.foren;
					selec.beneficiario = response.result.beneficiario;
					this.seleccion = selec;
					this.mostrar = true;
				});
			},
			Guardar(forence) {
				this.seleccion.foren = this.$store.state.claim.foren;
				Consulta.Procesar('Forence',{
					claim: this.seleccion,
					forence: forence,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result) {
						this.$toast.add({
							severity:'success',
							summary: this.$t('Éxito'), 
							detail: this.$t('Información Procesada'), 
							life: 3000
						});
						this.Mostrar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('Error'), 
							life: 3000
						});
					}
				});
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
